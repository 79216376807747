.App {
  text-align: center;
}
h1 {
  color: gray;
}
h2 {
  font-size: .8em;
}
.PropName {
  font-weight: bold;
  color: #6264A7;
}
.Logo {
  font-size: 45pt;
  color: #6264A7;
}
.Error {
  color: red;
}
a {
  color: inherit;
}